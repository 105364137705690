import { formatTime } from '@/utils/time';
import { useEffect, useState, useRef } from 'react';

export const useCountdownTimer = (initialTime: number, onFinish: () => void, msPerTick: number = 1000) => { // Default to 10000 ms
  const [timeLeft, setTimeLeft] = useState(initialTime * 1000);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!initialTime) return;
    setTimeLeft(initialTime * 1000);
  }, [initialTime]);

  useEffect(() => {
    if (!initialTime) return;

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= msPerTick) {
          clearInterval(timerRef.current!);
          onFinish();
          return 0;
        }
        return prevTime - msPerTick;
      });
    }, msPerTick);

    return () => clearInterval(timerRef.current!);
  }, [onFinish, initialTime, msPerTick]);

  const resetTimer = (newTime: number) => {
    if (newTime === 0 || !newTime) {
      onFinish();
      return;
    }
    setTimeLeft(newTime * 1000);
  };

  return {
    timeLeft,
    timeStr: formatTime(timeLeft, false),
    timeMsStr: formatTime(timeLeft, true),
    resetTimer,
  };
};