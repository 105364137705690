import Image from 'next/image';
import { Player } from '@lottiefiles/react-lottie-player';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import crystalImage from '@/assets/images/crystal-image.png';
import { Button } from '../buttons/Button';
import { BaseModal, closeModal } from '../BaseModal';
import { useUserStore } from '@/stores/user';
import diamondsAnimation from '@/assets/animations/diamonds.json';
import ModalBg from '@/assets/images/vector/modal-bg.svg';
import { useEffect, useRef } from 'react';
import { AnimatedCounter } from '../AnimatedCounter';

export const SuccessPaymentModal: React.FC = () => {
  const { userStats } = useUserStore();
  const modal = useRef<HTMLDialogElement>(null);
  const animation = useRef<Player | null>(null);

  useEffect(() => {
    if (modal.current) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'open') {
            const isOpen = (mutation.target as HTMLDivElement).hasAttribute('open');
            if (isOpen) {
              animation?.current?.stop();
              animation?.current?.play();
            }
          }
        });
      });

      observer.observe(modal.current, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [modal]);

  return (
    <BaseModal className="overflow-hidden" ref={modal} id={Modals.SuccessPayment}>
      <div className="relative rounded-[32px] w-[95%] sm:w-[380px] p-0">
        <div className="relative bg-black-200 rounded-[inherit] p-2 py-5 sm:p-8 z-10 overflow-hidden max-h-[300px]">
          <ModalBg className="absolute z-0 left-0 top-0 w-full" />
          <div className="relative z-10">
            <div className="flex flex-col items-center">
              <Text variant="h2" className="text-center">
                🌶️🌶️🌶️
              </Text>
              <Text variant="h2" className="mb-4 text-white text-center">
                {"You've received gems"}
              </Text>
              <div className="flex relative mb-5 w-full max-w-[272px]">
                <div className="flex-col bg-pink z-10 rounded-lg w-full pt-2 pb-3 border border-black items-center">
                  <Text className="mb-1 text-center" textColor={'white'} variant={'h5'}>
                    Your balance:
                  </Text>
                  <div className="flex justify-center">
                    <Image className="w-[30px] h-[30px] mr-2" src={crystalImage} alt="crystal" />
                    <Text textColor={'white'} variant={'h1'}>
                      <AnimatedCounter amount={userStats.gem_balance} />
                    </Text>
                  </div>
                </div>
                <div className="rounded-lg bg-black h-full w-full absolute z-0 top-[3px] left-[3px]"></div>
              </div>
              <Button
                onClick={() => closeModal(Modals.SuccessPayment)}
                className={'w-full max-w-[80%] sm:max-w-[200px]'}
                wrapperClass={'bg-white group-active/button:bg-black py-2 sm:py-4'}
              >
                <Text className="text-black group-active/button:text-white" variant="btn-1">
                  Close
                </Text>
              </Button>
            </div>
          </div>
        </div>
        <div className={'absolute left-[3px] top-[3px] h-full w-full border border-black rounded-[34px] z-0 bg-pink'} />
        <Player
          ref={animation}
          keepLastFrame={true}
          className="absolute z-0 -left-[100px] -top-[100px]"
          autoplay={false}
          loop={false}
          speed={1}
          src={diamondsAnimation}
          style={{ height: '450px', width: '600px' }}
        />
      </div>
    </BaseModal>
  );
};
