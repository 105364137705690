import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { PatternModal } from '@/components/PatternModal';
import { Button } from '../buttons/Button';
import { closeModal } from '../BaseModal';
import LocalStorageUtils from '@/utils/storage';
import { IS_ONBOARDED } from '@/contants/storageKeys';

export const WelcomeModal: React.FC = () => {
  const handleClickAgree = () => {
    LocalStorageUtils.setItem(IS_ONBOARDED, true);
    closeModal(Modals.Welcome);
  };

  const handleClickDisagree = () => {
    window.location.href = "https://i.kym-cdn.com/entries/icons/original/000/040/566/sirthisisawendysmeme.jpg";
  };

  return (
    <PatternModal id={Modals.Welcome} className='backdrop-filter backdrop-blur-md bg-black bg-opacity-50'>
      <div className="flex flex-col items-center">
        <Text variant="h2" className="mb-7 text-white text-center">
          Welcome
        </Text>
        <div className="flex flex-col w-full max-w-[90%] mb-7">
          <Text variant="h4" className="mb-1.5 text-blue text-left">
            Rules:
          </Text>
          <div className="flex items-left">
            <div className="w-2 h-2 rounded-full bg-pink border border-black mr-2 mt-2"></div>
            <Text className="leading-[28px]" textColor="white" variant="h6">
              Chatbots are for adults only.
            </Text>
          </div>

          <div className="flex items-left">
            <div className="w-2 h-2 rounded-full bg-pink border border-black mr-2 mt-2"></div>
            <Text className="leading-[28px]" textColor="white" variant="h6">
              Inciting real-world violence is forbidden.
            </Text>
          </div>

          <div className="flex items-left">
            <div className="w-2 h-2 rounded-full bg-pink border border-black mr-2 mt-2"></div>
            <Text className="leading-[28px]" textColor="white" variant="h6">
            Underage content is prohibited.
            </Text>
          </div>

          <div className="flex items-left">
            <div className="w-2 h-2 rounded-full bg-pink border border-black mr-2 mt-2"></div>
            <Text className="leading-[28px]" textColor="white" variant="h6">
            All roleplay participants must be 18+.
            </Text>
          </div>

        </div>
        <div className="flex justify-between w-full">
          <Button onClick={handleClickDisagree} className={'w-auto'} wrapperClass={'bg-white group-active/button:bg-black'}>
            <Text className="px-[34px] text-black group-active/button:text-white" variant="btn-1">
              Disagree
            </Text>
          </Button>
          <Button onClick={handleClickAgree} className={'w-auto'} wrapperClass={'bg-pink group-active/button:bg-black'}>
            <Text className="px-[34px]" textColor="white" variant="btn-1">
              I Agree
            </Text>
          </Button>
        </div>
      </div>
    </PatternModal>
  );
};
