import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { PatternModal } from '@/components/PatternModal';
import { Button } from '@/components/buttons/Button';
import { closeModal } from '@/components/BaseModal';
import { ConfirmationMessage } from '@/types/confirmation';
import { SquareCheckBig, Info, ShieldX, ShieldAlert } from 'lucide-react';


type Props = {
  confirmation?: ConfirmationMessage | null;
  onClose: () => void;
};

export const ConfirmationModal: React.FC<Props> = ({ confirmation, onClose }) => {
  return (
    <PatternModal id={Modals.GenericConfirmation}>
      <div className="flex flex-col items-center">
        
        {confirmation?.type && (
          <div className="mb-4">
            {confirmation.type === 'success' && (
              <SquareCheckBig className="h-20 w-20 text-green" />
            )}
            {confirmation.type === 'error' && (
              <ShieldX className="h-20 w-20 text-red-500" />
            )}
            {confirmation.type === 'info' && (
              <Info className="h-20 w-20 text-white-100" />
            )}
          </div>
        )}

        
        <div className="flex w-full max-w-[280px]">
          <Text variant="h5" className="mb-8 text-white text-center">
            {confirmation?.message}
          </Text>
        </div>
        <div className="flex justify-center w-full">
          <Button
            onClick={() => {
              closeModal(Modals.GenericConfirmation);
              onClose();
            }}
            className="w-auto"
            wrapperClass="bg-white group-active/button:bg-black"
          >
            <Text className="px-11 text-black group-active/button:text-white" variant="btn-1">
              Close
            </Text>
          </Button>
        </div>
      </div>
    </PatternModal>
  );
};
