import { StateBase } from '@/types/store';

export const stateBase: StateBase = {
  initialized: false,
  error: null,
  loading: false,

  reset: () => undefined,
  initialize: () => undefined,
};

export async function callWithLoading<T>(action: Promise<T>, set: any) {
  try {
    set({ loading: true });
    return await action;
  } catch (error) {
    set({ error });
  } finally {
    set({ loading: false });
  }
}
