import { cn } from '@/lib/utils';
import { FC } from 'react';

export type ButtonProps = {
  className?: string;
  wrapperClass?: string;
  overlayClassName?: string;
  onClick: () => void;
  disabled?: boolean;
  disabledClassName?: string;
};

export const Button: FC<React.PropsWithChildren<ButtonProps>> = ({
  className,
  overlayClassName,
  wrapperClass,
  children,
  onClick,
  disabled = false,
  disabledClassName,
}) => {
  const handleClick = () => {
    if (!disabled) onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={cn(
        'group/button w-full flex relative rounded-lg border border-transparent',
        className,
        disabled ? 'cursor-default' : 'cursor-pointer',
      )}
    >
      <div
        className={cn(
          'w-full flex flex-row items-center justify-center py-4 relative z-10 -translate-x-[3px] -translate-y-[3px] rounded-lg bg-blue transition-all duration-150',
          !disabled ? 'group-hover/button:translate-x-[0] group-hover/button:translate-y-[0]' : '',
          wrapperClass,
          disabledClassName,
        )}
      >
        {children}
      </div>
      <div
        className={cn('block z-0 w-full h-full absolute left-[0] top-[0] bg-black rounded-lg', overlayClassName)}
      ></div>
    </div>
  );
};

Button.displayName = 'Button';
