import { Banner } from '@/models/banner';
import api, { callApi } from './config';
import { IError } from '@/models/common';

const bannersApi = {
  async getBanners(tags: string[]) {
    const url = '/user/banners';
    const params = new URLSearchParams();
    tags.forEach(tag => params.append('tags', tag));
    return callApi<Banner[], IError>(api.get(url, { params }));
  },
};

export default bannersApi;
