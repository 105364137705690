import { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { Player } from '@lottiefiles/react-lottie-player';
import { cn } from '@/lib/utils';
import { Text } from '@/components/Text';
import { Product, ProductType } from '@/types/products';
import { formatPriceInCents } from '@/utils/price';
import { formatNumberWithCommas } from '@/utils/number';
import StoreCardBg from '@/assets/images/vector/store-card-bg.svg';
import StoreCrystal from '@/assets/images/vector/store-crystal.svg';
import StoreCrystalSm from '@/assets/images/vector/store-crystal-sm.svg';
import StoreCrystalXs from '@/assets/images/vector/store-crystal-xs.svg';

import crystalImage from '@/assets/images/crystal-image.png';
import { Button } from './buttons/Button';
import { useCountdownTimer } from '@/hooks/useCountdownTimer';
import dotsAnimation from '@/assets/animations/dots.json';

type Props = {
  product: Product;
  onPurchase: () => void;
  className?: string;
  disabled?: boolean;
  isBestValue: boolean;
  isSelected: boolean;
  loading?: boolean;
};

export const StoreCard: FC<Props> = ({
  product,
  className,
  onPurchase,
  isBestValue,
  isSelected,
  loading,
}) => {
  const isFreePlan = product.type === ProductType.DailyGem;
  const [btnDisabled, setBtnDisabled] = useState<boolean>(
    isFreePlan ? 
      !!(product.remaining_time && product.remaining_time > 0 || loading)
      : !!(isSelected && loading)
  );

  useEffect(() => {
    setBtnDisabled(isFreePlan ? !!(product.remaining_time && product.remaining_time > 0 || loading) : !!(isSelected && loading));
  }, [isSelected, loading, product.remaining_time, isFreePlan]);

  const onTimerFinish = () => {
    setBtnDisabled(false);
  };

  const { timeLeft, timeStr } = useCountdownTimer(product.remaining_time ?? 0, onTimerFinish);

  const renderCrystals = (crystalsIconsAmount: number) => {
    return (
      <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
        <StoreCrystal />
        {crystalsIconsAmount >= 1 && (
          <div className="absolute right-[13px] sm:right-[28px] top-[18px] rotate-[100deg]">
            <StoreCrystalSm />
          </div>
        )}
        {crystalsIconsAmount >= 2 && (
          <div className="absolute left-[10px] sm:left-[32px] top-[22px]">
            <StoreCrystalSm />
          </div>
        )}
        {crystalsIconsAmount >= 3 && (
          <div className="absolute left-[32px] sm:left-[52px] bottom-[10px]">
            <StoreCrystalXs />
          </div>
        )}
        {crystalsIconsAmount >= 4 && (
          <div className="absolute right-[32px] sm:right-[62px] bottom-[10px] rotate-[-10deg]">
            <StoreCrystalXs />
          </div>
        )}
      </div>
    );
  };

  const renderPriceOnButton = () => {
    if (isFreePlan) {
      return <Text className="text-btn-2 text-lg md:text-btn-1" textColor="white" variant="btn-1">Claim Free</Text>;
    }

    if (product.original_price) {
      return (
        <Text className="text-btn-2 md:text-btn-1" textColor="white" variant="btn-2">
          <div className="flex items-center justify-center text-white/75 mb-[-5px]">
            <Text className={cn("text-xs mr-1 relative text-white/50 line-through")}>
              was {formatPriceInCents(product.original_price)}
            </Text>
          </div>
          <div className='items-center justify-center mt-[2px]'>
            <Text className="text-white text-center" variant="h3">{formatPriceInCents(product.usd_cents)}</Text>
          </div>
        </Text>
      );
    }

    return  (
      <Text className="text-btn-2 md:text-btn-1" textColor="white" variant="btn-2">
      <div className='items-center justify-center mt-1'>
      <Text className="text-white text-center" variant="h3">{formatPriceInCents(product?.usd_cents)} </Text>
      </div>
    </Text>

    );
  };

  const renderCountdown = () => {
    if (!timeLeft) return (
      <div className="flex items-center">
        <div className="text-white rounded-md px-1 py-0.5">&nbsp;</div>
      </div>
    );
  
    const timeParts = timeStr.replace(/[^0-9:]/g, '').split(':').filter(Boolean);
  
    return (
      <div className="flex space-x-1">
        {timeParts.map((unit, index) => (
          <div key={index} className="flex items-center">
            <div className="bg-black-100 text-white rounded-md px-1 py-0">
              {unit}
            </div>
            {index < timeParts.length - 1 && <Text className="flex text-black text-xl ml-1 text-center">:</Text>}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={cn('flex items-start relative rounded-xl w-full max-w-[234px] mb-4', className)}>
      {/* {isBestValue && (
        <div className="absolute top-0 left-0 w-full flex justify-center z-20">
          <div className="flex w-[100px] h-[30px] bg-pink-gr items-center justify-center text-white text-center rounded-xl -translate-y-1/2">
            Best Value
          </div>
        </div>
      )} */}

    {product.description && (
        <div className="absolute top-0 left-0 w-full flex justify-center z-20">
          <div className="flex w-[100px] h-[30px] bg-pink-gr items-center justify-center text-white text-center rounded-xl -translate-y-1/2">
            {product.description}
          </div>
        </div>
      )}

      <div className={cn(
        'flex flex-col w-full rounded-xl bg-white p-2 md:px-4 pt-[18px] md:pb-1 z-10 relative items-center',
        isBestValue ? 'border-2 border-red-500' : 'border-2 border-gray'
      )}>

        <div className="flex w-full rounded-lg relative mb-[11px] items-center">
          <div className="flex w-full relative z-10 rounded-lg items-center justify-center">
            <div className="absolute w-full inset-0 top-0 left-0 items-center justify-center rounded-lg overflow-hidden">
              <StoreCardBg className="h-full w-full" />
              {/* <div className=" h-full w-full absolute z-0 rounded-lg bg-black"></div> */}
            </div>
            <div className="flex flex-row mt-3 mb-1 items-center">
              <Image className="w-[30px] h-[30px] mr-2" src={crystalImage} alt="crystal" />
              <Text className="text-h5 md:text-4xl text-white" variant="h1">
                {formatNumberWithCommas(product.gem_amt)}
              </Text>
          </div>
          </div>
          <div className="rounded-lg bg-black h-full w-full absolute z-0"></div>
        </div>



        {product.promotion_label ? (
          <Text className="text-h6 text-red-500 whitespace-nowrap " variant="h5">
            {product.promotion_label || "\u00A0"}
          </Text>
        ) : (
          <Text className="text-black/75 text-h6 md:text-h6 whitespace-nowrap" variant="h5">
            <span className="pre-line">{product.name || "\u00A0"}</span>
          </Text>
        )}
        {/* <Text className="text-black/75 text-h6 md:text-h6 whitespace-nowrap mb-0" variant="h5">
          <span className="pre-line">{product.description || "\u00A0"}</span>
        </Text> */}


        {/* <div className="flex mb-2 items-center md:items-start">
          <Image className="w-[30px] h-[30px] mr-2" src={crystalImage} alt="crystal" />
          <Text className="text-h5 md:text-h1" variant="h1">
            {formatNumberWithCommas(product.gem_amt)}
          </Text>
        </div> */}

        <div className="flex flex-col items-center mt-0 mb-0">
          <div className={cn(
            'px-2 py-1 rounded-md justify-center items-center inline-flex relative group',
            !timeLeft ? 'border-0 border-white bg-white' : ''
          )}>
            {renderCountdown()}
          </div>
        </div>

        <Button
          disabled={btnDisabled}
          onClick={onPurchase}
          className={cn("w-full")}
          wrapperClass={cn(
            'bg-pink-gr group-active/button:bg-black-gr h-[42px] sm:h-[52px]',
            btnDisabled ? 'bg-gray group-active/button:bg-gray' : '',
            !loading ? 'py-2 md:py-[13px]' : 'py-0'
          )}
        >
          {!loading && renderPriceOnButton()}
          {loading && <Player autoplay loop src={dotsAnimation} className="w-[70px] h-[30px]" />}
        </Button>
      </div>

      <div className="rounded-xl bg-black h-full w-full absolute z-0 top-[3px] left-[3px]"></div>
    </div>
  );
};
