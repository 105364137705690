export type ToastMessage = {
  message: string;
  type: ToastType;
};

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
