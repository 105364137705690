import { DefaultJWT } from 'next-auth/jwt';

export enum SocialProvidersEnum {
  DISCORD = 'discord',
  GOOGLE = 'google',
  CREDENTIALS = 'credentials',
}

declare module 'next-auth/jwt' {
  interface JWT extends DefaultJWT {
    provider: 'discord' | 'google' | 'credentials';
    refreshToken: string;
    accessTokenExpires?: number;
  }
}
