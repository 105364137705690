const normalizeSrc = src => {
  return src.startsWith('/') ? src.slice(1) : src;
};

const defaultLoader = ({ src, width, quality }) => {
  const params = [`w=${width}`];
  params.push(`q=${quality || 75}`)
  const paramsString = params.join('&');
  return `${src}?${paramsString}`;
};
  
const predefinedSizes = [32, 48, 64, 96, 128, 256, 320, 384, 448, 512, 640, 768, 896, 1024, 1200, 1440, 1600, 1920, 2048, 2560, 3000, 3440, 3840];

export default function cloudflareLoader ({ src, width, quality }) {
  if (!src.includes('r2.cloudflarestorage.com')) {
    return defaultLoader({ src, width, quality });
  }

  // Find the closest predefined width
  const closestWidth = predefinedSizes.reduce((prev, curr) => 
    Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev
  );

  src = src.replace(/^https?:\/\/[^\/]+\//, '');
  const params = [`width=${closestWidth}`];
  params.push(`q=${quality || 75}`);
  const paramsString = params.join(',');
  return `https://images.thotchat.ai/${paramsString}/${normalizeSrc(src)}`;
};
