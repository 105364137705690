import { FC } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { usePrevious } from '@/hooks/usePrevious';

type AnimatedCounterProps = {
  amount: number;
  className?: string;
};

export const AnimatedCounter: FC<AnimatedCounterProps> = ({ amount, className }) => {
  const prevAmount = usePrevious(amount) ?? 0;

  const { number } = useSpring({
    from: { number: prevAmount },
    number: amount,
    delay: 200,
    config: { duration: 1500 },
  });

  return <animated.span className={className}>{number.to(n => n.toFixed(0))}</animated.span>;
};
