'use client';
import { Component } from 'react';
import { Button } from '@/components/buttons/Button';
import { Text } from '@/components/Text';
import { withRouter, NextRouter } from 'next/router';
import ModalBg from '@/assets/images/vector/modal-bg.svg';

interface ErrorBoundaryState {
  error: any;
  errorInfo: any;
}

interface ErrorBoundaryProps {
  router: NextRouter;
  children: React.ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    return { error: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    });
  }

  handleGoHome = () => {
    this.props.router.push('/').then(() => {
      this.setState({ error: null, errorInfo: null });
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    if (error || errorInfo) {
      const details = error ? error.message : JSON.stringify(errorInfo);
      return (
        <div className={'flex w-full h-screen bg-black-100 flex items-center justify-center'}>
          <div className="relative rounded-[32px] w-[380px] p-0">
            <div className="relative bg-black-200 rounded-[inherit] p-8 z-10 overflow-hidden max-h-[300px] flex justify-center">
              <ModalBg className="absolute z-0 left-0 top-0 w-full" />
              <div className="relative z-10 w-full max-w-[248px]">
                <Text variant="h1" className="mb-6 text-white text-center">
                  Oops 😥
                </Text>
                <Text className="mb-4 text-center" variant="body-1" textColor="white">
                  {`Something went wrong: ${details}`}
                </Text>
                <Button wrapperClass="bg-pink" onClick={this.handleGoHome}>
                  <Text textColor="white" variant="btn-1">
                    Go to home page
                  </Text>
                </Button>
              </div>
            </div>
            <div className={'absolute left-[3px] top-[3px] h-full w-full bg-pink rounded-[34px] z-0'} />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
