import Cookies from 'js-cookie';

const UTM_COOKIE_NAME = 'utm_data';
const UTM_DATA_UPDATE_DATE = 'utm_data_update_date';
const UTM_DATA_REPORT_DATE = 'utm_data_report_date';
const COOKIE_EXPIRY = 30; // days

export function setUtmCookies() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'tracker', 'campaign_id'];
    
    const newUtmData = utmParams.reduce((acc, param) => {
      const value = urlParams.get(param);
      // Remove other query params from the value
      if (value) acc[param] = value.split('?')[0];
      return acc;
    }, {} as Record<string, string>);
  
    // Get existing UTM data from cookie
    const existingUtmDataString = Cookies.get(UTM_COOKIE_NAME);
    const existingUtmData = existingUtmDataString ? JSON.parse(existingUtmDataString) : {};
  
    // Check if there are any changes
    const hasChanges = Object.keys(newUtmData).some(key => newUtmData[key] !== existingUtmData[key]);
  
    if (hasChanges) {
        // Merge new data with existing data
        const updatedUtmData = { ...existingUtmData, ...newUtmData };
        Cookies.set(UTM_COOKIE_NAME, JSON.stringify(updatedUtmData), { expires: COOKIE_EXPIRY });
        
        // Set the update date in UTC
        const utcNow = new Date().toUTCString();
        Cookies.set(UTM_DATA_UPDATE_DATE, utcNow, { expires: COOKIE_EXPIRY });
    }
}

export function shouldReportUtmData(): boolean {
    const utmDataReportDate = Cookies.get(UTM_DATA_REPORT_DATE);
    const utmDataUpdateDate = Cookies.get(UTM_DATA_UPDATE_DATE);
    return !utmDataReportDate || (utmDataUpdateDate != null && utmDataUpdateDate > utmDataReportDate);
}

export function getUtmData(): Record<string, string> | null {
    const utmCookie = Cookies.get(UTM_COOKIE_NAME);    
    if (!utmCookie) {
      return null;
    }
  
    try {
      const parsedData = JSON.parse(utmCookie);
      return parsedData;
    } catch (error) {
      console.error(`[ERROR] Failed to parse UTM cookie:`, error);
      return null;
    }
  }