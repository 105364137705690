import { SocialProvidersEnum } from '@/types/auth';
import googleIcon from '@/assets/social-icons/google-icon.svg';
import discordIcon from '@/assets/social-icons/discord-icon.svg';
import emailIcon from '@/assets/social-icons/email-icon.svg';

export const getSocialIconByProvider = (provider: SocialProvidersEnum) => {
  switch (provider) {
    case SocialProvidersEnum.GOOGLE:
      return googleIcon;
    case SocialProvidersEnum.DISCORD:
      return discordIcon;
    case SocialProvidersEnum.CREDENTIALS:
      return emailIcon;
    default:
      return googleIcon;
  }
};
