type StorageValue = string | number | boolean | Record<string, unknown>;

class LocalStorageUtils {
  static setItem(key: string, value: StorageValue): void {
    if (typeof window !== 'undefined') {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(key, stringValue);
    }
  }
  static getItem<T>(key: string): T | null {
    if (typeof window !== 'undefined') {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : null;
    }
    return null;
  }
  static removeItem(key: string): void {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  }
  static hasItem(key: string): boolean {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key) !== null;
    }
    return false;
  }
  static clear(): void {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
  }
}

export default LocalStorageUtils;
