import { CharactersState, charactersStorePersistKey } from '@/stores/characters';
import LocalStorageUtils from '@/utils/storage';

export function clearPersist() {
  const dataRaw = localStorage.getItem(charactersStorePersistKey);

  if (!dataRaw) {
    return;
  }

  const currentData: { version: number; state: Partial<CharactersState> } = JSON.parse(dataRaw);

  delete currentData.state.chatHistory;
  delete currentData.state.recentChats;

  localStorage.setItem(charactersStorePersistKey, JSON.stringify(currentData));
}

const welcomMessageMapKey = 'welcome-messages';

export function clearWelcomMessagesMap() {
  LocalStorageUtils.removeItem(welcomMessageMapKey);
}

export function setWelcomeMessageId(charId: number, welcomeMessageId: number) {
  const prevMap = LocalStorageUtils.getItem<{ [x: number]: number }>(welcomMessageMapKey) || {};

  prevMap[charId] = welcomeMessageId;

  LocalStorageUtils.setItem(welcomMessageMapKey, prevMap);
}

export function getWelcomeMessageId(charId: number) {
  const prevMap = LocalStorageUtils.getItem<{ [x: number]: number }>(welcomMessageMapKey);
  return prevMap && prevMap[charId];
}
