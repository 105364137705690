import { cn } from '@/lib/utils';
import { PropsWithChildren, forwardRef, memo } from 'react';

type Props = {
  id: string;
  className?: string;
  onClose?: () => void;
};

const RefModal = forwardRef<HTMLDialogElement, PropsWithChildren<Props>>(function Modal(
  { id, children, className, onClose },
  ref,
) {
  return (
    <dialog 
      id={id} 
      ref={ref} 
      className={cn('modal bg-black/[.5] z-40 backdrop-filter backdrop-blur-md bg-black bg-opacity-50', className)} 
      onClose={() => {
        onClose && onClose();
      }}
    >
      {children}
      <form method="dialog" className="modal-backdrop absolute left-0 top-0 w-full h-full">
        <button className="cursor-default" aria-label="close"></button>
      </form>
    </dialog>
  );
});

export const BaseModal = memo(RefModal);

export function openModal(id: string) {
  const modal = document.getElementById(id) as HTMLDialogElement;
  modal?.show();

  if (modal && window) {
    window.dispatchEvent(new CustomEvent('modalOpen', {detail: {id}}));
  }
}

export function closeModal(id: string) {
  const modal = document.getElementById(id) as HTMLDialogElement;
  modal?.close();

  if (modal && window) {
    window.dispatchEvent(new CustomEvent('modalClose', {detail: {id}}));
  }
}
