export const formatTime = (time: number, showMs: boolean = true): string => {
  const milliseconds = Math.floor(time % 1000)
    .toString()
    .padStart(3, '0');
  const timeInSeconds = Math.floor(time / 1000);
  const days = Math.floor(timeInSeconds / 3600 / 24)
    .toString()
    .padStart(2, '0');
  const hours = Math.floor(timeInSeconds / 3600 % 24)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0');
  return `${days > '00' ? days + 'd:' : ''}${(hours > '00' || days > '00') ? hours + 'h:' : ''}${minutes}m:${seconds}s${showMs ? `:${milliseconds}ms` : ''}`;
};
