import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Player } from '@lottiefiles/react-lottie-player';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import crystalImage from '@/assets/images/crystal-image.png';
import { Button } from '../buttons/Button';
import { BaseModal, closeModal } from '../BaseModal';
import { useUserStore } from '@/stores/user';
import diamondsAnimation from '@/assets/animations/diamonds.json';
import ModalBg from '@/assets/images/vector/modal-bg.svg';
import { useEffect, useRef } from 'react';
import { GemIcon, Telescope, CalendarCheck, CircleX } from 'lucide-react';
import { AnimatedCounter } from '../AnimatedCounter';

export const SuccessSubscriptionModal: React.FC = () => {
  const router = useRouter();
  const { userStats } = useUserStore();
  const modal = useRef<HTMLDialogElement>(null);
  const animation = useRef<Player | null>(null);

  useEffect(() => {
    if (modal.current) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'open') {
            const isOpen = (mutation.target as HTMLDivElement).hasAttribute('open');
            if (isOpen) {
              animation?.current?.stop();
              animation?.current?.play();
            }
          }
        });
      });

      observer.observe(modal.current, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [modal]);

  return (
    <BaseModal className="overflow-hidden" ref={modal} id={Modals.SuccessSubscription}>
      <div className="absolute flex flex-col items-center justify-center w-full h-full"/>
      <div className="relative rounded-[32px] w-[95%] sm:w-[480px] p-0">
        <div className="relative bg-black-200 rounded-[inherit] p-2 py-5 sm:p-8 z-10 overflow-hidden max-h-[500px]">
          <ModalBg className="absolute z-0 left-0 top-0 w-full" />
          <div className="relative z-10">
            <div className="flex flex-col items-center">
              <Text variant="h2" className="mt-4 mb-1 text-white text-center">
                {"Subscription Successful!"}
              </Text>
              <Text variant="body-1" className="mb-4 text-white opacity-80 text-center">
                {"Welcome to thotchat.ai Premium!"}
              </Text>

              <div className="flex flex-row w-full items-center mb-4">
                <GemIcon className="w-6 h-6 mr-2 text-pink" />
                <Text className="text-white w-11/12 mt-1" variant="h6">
                  You have received <span className="text-pink">1000 gems</span> as a part of your subscription!
                </Text>
              </div>

              <div className="flex flex-row w-full items-center mb-4">
                <CalendarCheck className="w-6 h-6 mr-2 text-pink" />
                <Text className="text-white w-11/12 mt-1" variant="h6">
                  Claim <span className="text-pink">25 FREE gems</span> every day!
                </Text>
              </div>

              <div className="flex flex-row w-full items-center mb-4">
                <Telescope className="w-6 h-6 mr-2 text-pink" />
                <Text className="text-white w-11/12 mt-1" variant="h6">
                  Enjoy exploring all the premium features and content!
                </Text>
              </div>

              <Button
                onClick={() => {
                  closeModal(Modals.SuccessSubscription);
                  // router.push('/');
                }}
                className={'w-full mt-4 max-w-[80%] sm:max-w-[200px]'}
                wrapperClass={'bg-pink group-active/button:bg-black py-2 sm:py-4'}
              >
                <Text className="text-white group-active/button:text-white" variant="btn-1">
                  Explore Now
                </Text>
              </Button>
            </div>
          </div>
        </div>

        <div className={'absolute left-[3px] top-[3px] h-full w-full border border-black rounded-[34px] z-0 bg-pink'} />
        <Player
          ref={animation}
          keepLastFrame={true}
          className="absolute z-0 -left-[100px] -top-[100px]"
          autoplay={false}
          loop={false}
          speed={1}
          src={diamondsAnimation}
          style={{ height: '450px', width: '600px' }}
        />
      </div>
    </BaseModal>
  );
};
