import { useCallback, memo, lazy, Suspense } from 'react';
import { Modals } from '@/types/modals';
import { BaseModal, closeModal } from '../BaseModal';
import { useState, useEffect } from 'react';
import { useProductsStore } from '@/stores/products';

interface Props {
  onPaymentSucceeded: () => void;
  onPaymentError: (error: string) => void;
}

const ProductCheckoutModal: React.FC<Props> = memo(({ onPaymentSucceeded, onPaymentError }) => {
  const { selectedCheckoutSession, verifyPurchase } = useProductsStore();
  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const [LazyEmbeddedCheckout, setLazyEmbeddedCheckout] = useState<React.ComponentType<any> | null>(null);
  const [LazyEmbeddedCheckoutProvider, setLazyEmbeddedCheckoutProvider] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadStripeLibrary = async () => {
      try {
        const { loadStripe } = await import('@stripe/stripe-js/pure');
        setStripePromise(loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY));
      } catch (error) {
        console.error('Failed to load Stripe library:', error);
      }
    };

    loadStripeLibrary();
  }, []);

  useEffect(() => {
    if (selectedCheckoutSession?.client_secret) {
      const loadStripeComponents = async () => {
        const { EmbeddedCheckout, EmbeddedCheckoutProvider } = await import('@stripe/react-stripe-js');
        setLazyEmbeddedCheckout(() => EmbeddedCheckout);
        setLazyEmbeddedCheckoutProvider(() => EmbeddedCheckoutProvider);
      };

      loadStripeComponents();
    }
  }, [selectedCheckoutSession]);

  const handleComplete = useCallback(async () => {
    if (selectedCheckoutSession?.session_id) {
      const verified = await verifyPurchase(selectedCheckoutSession.session_id);
      if (verified) {
        onPaymentSucceeded();
      } else {
        onPaymentError('Payment failed or was incomplete.');
      }
      return;
    }
    useProductsStore.setState({ selectedProductId: null });
  }, [selectedCheckoutSession]);

  if (!selectedCheckoutSession?.client_secret) {
    return null;
  }

  return (
    <BaseModal className="overflow-auto backdrop-filter backdrop-blur-md bg-black bg-opacity-50" id={Modals.ProductCheckout}>
      <div className="relative w-[92%] sm:w-[480px]">
        <div className={'absolute h-full w-full'} />
        {selectedCheckoutSession?.client_secret && LazyEmbeddedCheckout && LazyEmbeddedCheckoutProvider && (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyEmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{
                clientSecret: selectedCheckoutSession.client_secret,
                onComplete: handleComplete,
              }}
              key={selectedCheckoutSession.client_secret}
            >
              <LazyEmbeddedCheckout className="max-h-[90dvh] relative z-10" />
            </LazyEmbeddedCheckoutProvider>
          </Suspense>
        )}
      </div>
    </BaseModal>
  );
});

ProductCheckoutModal.displayName = 'ProductCheckoutModal';

export { ProductCheckoutModal };