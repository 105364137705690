import { UserProfile } from '@/models/user';
import api, { callApi } from './config';
import { UpdateUserProfileRequest, UpdateUserProfileResponse, UserStatsResponse } from '@/types/user';
import { IError } from '@/models/common';

const userApi = {
  async getUserProfile() {
    const url = '/user/profile';
    return callApi<UserProfile, IError>(api.get(url));
  },
  async updateUserProfile(data: UpdateUserProfileRequest) {
    const url = '/user/profile';
    return callApi<UpdateUserProfileResponse, IError>(api.post(url, data));
  },
  async getUserStats() {
    const url = '/user/state';
    return callApi<UserStatsResponse, IError>(api.get(url));
  },
  async updateEmailVerified(email: string) {
    const url = `/user/email_verified/${encodeURIComponent(email)}`;
    return callApi<void, IError>(api.post(url));
  },
  async deleteAccount(notes: string) {
    const url = `/user/delete`;
    return callApi<void, IError>(api.post(url, { notes }));
  },
};

export default userApi;
