import { cn } from '@/lib/utils';
import { PropsWithChildren } from 'react';
import ModalBg from '@/assets/images/vector/modal-bg.svg';
import { BaseModal } from './BaseModal';

type Props = {
  id: string;
  className?: string;
  shadowColor?: string;
  onClose?: () => void;
};

export const PatternModal: React.FC<PropsWithChildren<Props>> = ({
  id,
  children,
  shadowColor = 'bg-blue',
  className,
  onClose,
}) => (
  <BaseModal className={className} id={id} onClose={onClose}>
    <div className="relative rounded-[32px] w-[95%] sm:w-[400px] p-0">
      <div className="relative bg-black-200 rounded-[inherit] p-[18px] pt-8 sm:p-8 z-10 overflow-hidden max-h-[360px]">
        <ModalBg className="absolute z-0 left-0 top-0 w-full" />
        <div className="relative z-10">{children}</div>
      </div>
      <div
        className={cn(
          'absolute left-[3px] top-[3px] h-full w-full border border-black rounded-[34px] z-0',
          shadowColor,
        )}
      />
    </div>
  </BaseModal>
);
