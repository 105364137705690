import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { clsx } from 'clsx';

const textVariants = cva('', {
  variants: {
    variant: {
      h1: 'font-heading text-h1',
      h2: 'font-heading text-h2',
      h3: 'font-heading text-h3',
      h4: 'font-heading text-h4',
      h5: 'font-heading text-h5',
      h6: 'font-heading text-h6',
      'title-sm': 'font-heading text-title-sm',
      'body-1': 'font-body text-body-1',
      'body-2': 'font-body text-body-2',
      'btn-1': 'font-button text-btn-1',
      'btn-2': 'font-button text-btn-2',
    },
    textColor: {
      white: 'text-white',
      black: 'text-black',
    },
  },
  defaultVariants: {
    variant: 'body-1',
    textColor: 'black',
  },
});

export interface TextProps extends HTMLAttributes<HTMLElement>, VariantProps<typeof textVariants> {
  className?: string;
  element?: keyof JSX.IntrinsicElements;
}

export const Text: FC<PropsWithChildren<TextProps>> = ({ variant, children, className, textColor, element }) => {
  const Tag = element ?? 'p';
  const combinedClasses = clsx(textVariants({ variant, textColor }), className);

  return <Tag className={combinedClasses}>{children}</Tag>;
};

Text.displayName = 'Text';
