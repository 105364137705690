import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getDeviceTags(): string[] {
  if (/Mobi|Android/i.test(navigator.userAgent)) {
    return ['mobile']
  } else {
    return ['desktop']
  }
}

export function getUTMTags(): string[] {
  const urlParams = new URLSearchParams(window.location.search);
  const utmTags: string[] = [];

  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
    const value = urlParams.get(param);
    if (value) {
      utmTags.push(value);
    }
  });

  return Array.from(new Set(utmTags));
}

export const scrollToElement = (elementId: string) => {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ block: "center", inline: "nearest", behavior: 'smooth' });
    }
  }, 100);
};

