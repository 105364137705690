import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import { PatternModal } from '@/components/PatternModal';
import { Button } from '@/components/buttons/Button';
import { closeModal } from '@/components/BaseModal';

type Props = {
  message?: string;
};

export const FailurePaymentModal: React.FC<Props> = ({ message }) => {
  return (
    <PatternModal id={Modals.FailurePayment}>
      <div className="flex flex-col items-center">
        <div className="flex w-full max-w-[280px]">
          <Text variant="h5" className="mb-8 text-white text-center">
            {`Payment Failed: ${message}`}
          </Text>
        </div>
        <div className="flex justify-center w-full">
          <Button
            onClick={() => closeModal(Modals.FailurePayment)}
            className="w-auto"
            wrapperClass="bg-white group-active/button:bg-black"
          >
            <Text className="px-11 text-black group-active/button:text-white" variant="btn-1">
              Close
            </Text>
          </Button>
        </div>
      </div>
    </PatternModal>
  );
};
