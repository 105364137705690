import api, { callApi } from './config';
import { IError } from '@/models/common';
import { GetProductsAndPromotionsResponse, ClaimDailyGemsResponse, PaymentCheckoutResponse, VerifyPurchaseResponse } from '@/types/products';
import { UserSubscription } from '@/models/user';

const productsApi = {
  async getProductsAndPromotions() {
    const url = '/store/catalog/web';
    return callApi<GetProductsAndPromotionsResponse, IError>(api.get(url));
  },
  async claimDailyGems() {
    const url = '/store/claim_gems';
    return callApi<ClaimDailyGemsResponse, IError>(api.post(url));
  },
  async checkoutProduct(product_id: number) {
    const url = `/payment/stripe/checkout/${product_id}`;
    return callApi<PaymentCheckoutResponse, IError>(api.get(url));
  },
  async verifyPurchase(session_id: string) {
    const url = `/payment/stripe/verify/${session_id}`;
    return callApi<VerifyPurchaseResponse, IError>(api.get(url));
  },
  async cancelSubscription(notes?: string) {
    const url = `/payment/stripe/cancel_subscription`;
    return callApi<UserSubscription, IError>(api.post(url, { notes }));
  },
};

export default productsApi;
