import charactersApi from '@/api/characters';
import { openModal } from '@/components/BaseModal';
import { stateBase } from '@/helpers/store';
import { ICharacter, ICharacterCreationResponse, IImageDataInAlbum, ICharacterQuotaResponse, ICharacterCreationChoices } from '@/models/characters';
import { IImageGenQuotaResponse } from '@/models/image';
import { IAudioMessageQuotaResponse, IUnlockImageResponse } from '@/models/chat';
import { ICharacterAlbums } from '@/types/album';
import { IChatDetails, IChatMessage, IRecentChat, IChatMessageQuotaResponse } from '@/models/chat';
import { IChatQuotaConfig, IChatsHistory, IFetchechedHistory } from '@/types/chat';
import { Modals } from '@/types/modals';
import { StateBase } from '@/types/store';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import { default_avatar } from '@/assets/remote-assets';
import { persist } from 'zustand/middleware';
import { useUserStore } from './user';
import { getWelcomeMessageId, setWelcomeMessageId } from '@/helpers/localStorage';
import posthog from 'posthog-js';

export interface CharactersState extends StateBase {
  characters: ICharacter[];
  sendingMessage: boolean;
  resetingChat: boolean;
  deletingChat: boolean;
  loadingMore: boolean;
  fetchedHistories: IFetchechedHistory[];
  chatHistory: IChatsHistory;
  characterAlbums: ICharacterAlbums;
  characterNewImages: Record<number, number>;
  recentChats: IRecentChat[];
  recentChatsLoading: boolean;

  checkBalance: (gem_cost?: number) => boolean;
  getActiveChars: () => void;
  resetUserCharacters: () => void;
  getUserRecentChats: () => void;
  getChatHistory: (characterId: number, lastMessageId?: number) => void;
  pruneExpiredChatHistory: (characterId: number) => void;
  sendMessage: (
    characterId: number,
    message: string,
    imageRequest: boolean,
    onQuotaHit: (config: IChatQuotaConfig) => void,
    onSuccess: () => void,
  ) => void;
  unlockMessages: (quotaResponse: IChatMessageQuotaResponse, characterId: number) => Promise<boolean>;
  regenerateLastMessage: (characterId: number, onQuotaHit: (config: IChatQuotaConfig) => void) => void;
  getById: (id: number) => ICharacter | undefined;
  resetChat: (id: number, onQuotaHit: (config: IChatQuotaConfig) => void, onSuccess: () => void) => void;
  deleteChat: (id: number, onSuccess: () => void) => void;
  unlockImage: (message: IChatMessage, characterId: number, onPaywallHit: (config: IUnlockImageResponse) => void) => Promise<boolean>;
  updateMessage: (messageId: number, characterId: number, fields: Partial<IChatMessage>) => void;
  updateAlbumImage: (characterId: number, updatedImageData: IImageDataInAlbum) => void;
  getCharacterAlbums: (id: number) => void;
  unlockAlbumImage: (imageData: IImageDataInAlbum, characterId: number, onPaywallHit: (config: IUnlockImageResponse) => void) => Promise<boolean>;
  generateAudio: (messageId: number, characterId: number, messageText: string, isAuthenticated: boolean, onPaywallHit: (config: IAudioMessageQuotaResponse) => void) => Promise<AudioGenerationResult>;
  unlockAudioMessages: (quotaResponse: IAudioMessageQuotaResponse) => Promise<boolean>;
  createCharacter: (choices: Record<string, any>, onPaywallHit: (config: ICharacterQuotaResponse) => void, variant?: string | null) => Promise<ICharacterCreationResponse | null>;
  renameCharacter: (characterId: number, name: string) => Promise<ICharacter | null>;
  unlockCharacters: (quotaResponse: ICharacterQuotaResponse) => Promise<boolean>;
  unlockImageGen: (quotaResponse: IImageGenQuotaResponse) => Promise<boolean>;
  updateGemBalance: (res: any) => Promise<void>;
  getTotalPendingImages: (characterId: number) => number;
  getCharacterNewImages: (characterId: number) => number;
  clearCharacterNewImages: (characterId: number) => void;
}

export const charactersStorePersistKey = 'characters-store';

function mergeHistories(next: IChatDetails, prev?: IChatDetails) {
  if (!prev) {
    return next;
  }

  const prevHistory: IChatMessage[] = prev.history;
  const resultHistory: IChatMessage[] = [...prevHistory];

  next.history.reverse().forEach(message => {
    let alreadyExist = resultHistory.some(m => m.id === message.id);
    if (!alreadyExist) {
      // This is to deal with recently sent messages might not have message id yet
      alreadyExist = resultHistory.some(m => m.text === message.text && Math.abs((m.time_sent ?? 0) - (message.time_sent ?? 0)) < 2);
    }

    if (!alreadyExist) {
      resultHistory.unshift(message);
    }
  });

  return {
    ...next,
    history: resultHistory,
  };
}

function onNotEnoughBalance() {
  showToast("You don't have enough gems - get more!", ToastType.ERROR);
  setTimeout(() => openModal(Modals.Storage), 1000);
}

type AudioGenerationResult = 
  | { success: true; audioBlob: Blob }
  | { success: false; reason: 'insufficient_gems' | 'audio_locked' | 'other_error' };

export const useCharactersStore = create<CharactersState>()(
  persist(
    (set, get) => ({
      ...stateBase,
      characters: [],
      fetchedHistories: [],
      recentChats: [],
      characterAlbums: {},
      characterNewImages: {},
      chatHistory: {},
      initialized: false,
      sendingMessage: false,
      resetingChat: false,
      deletingChat: false,
      loadingMore: false,
      recentChatsLoading: false,

      initialize: () => {
        const { initialized, getActiveChars } = get();

        if (!initialized) {
          getActiveChars();
        }
      },
      checkBalance: (gem_cost?: number) => {
        const {
          userStats: { gem_balance },
        } = useUserStore.getState();

        const enoughBalance = gem_cost && gem_cost <= gem_balance;

        if (!enoughBalance) {
          onNotEnoughBalance();
          return false;
        }
        return true;
      },
      getActiveChars: async () => {
        const res = await charactersApi.getActiveChars();
        if (res instanceof AxiosError) {
          const errorMessage = res.response?.data?.detail || res.message;
          showToast(`Can\'t get active chars. ${errorMessage}`, ToastType.ERROR);
        } else {
          set({ characters: res });
        }
      },
      resetUserCharacters: () => {
        set({ characters: get().characters.filter(char => !char.created_by_user) });
      },
      getChatHistory: async (characterId, lastMessageId) => {
        try {
          const welcomeMessageId = getWelcomeMessageId(characterId);

          // skip fetching history when reached end of the history
          if (welcomeMessageId && welcomeMessageId === lastMessageId) {
            return;
          }

          const { recentChats: chatPreviews } = get();
          const chatPreview = chatPreviews.find(ch => ch.character_id === characterId);

          // set history from preview for visual loading speed up
          set(state => {
            const result: Partial<CharactersState> = { loading: true, loadingMore: !!lastMessageId };

            if (!state.chatHistory[characterId] && !!chatPreview?.history.length) {
              result.chatHistory = {
                ...state.chatHistory,
                [characterId]: {
                  history: chatPreview.history,
                  character: {
                    id: chatPreview.character_id,
                    name: chatPreview.character_name,
                    description: '',
                    avatar_url: chatPreview.avatar_url,
                    profile: '',
                    subtitle: '',
                  },
                },
              };
            }

            return result;
          });

          const historyRes = await charactersApi.getChatDetails(characterId, lastMessageId);

          if (historyRes) {
            // reached end of the list, remember last message id for this char, to skip further calls
            if (historyRes.history.length === 0 && lastMessageId) {
              setWelcomeMessageId(characterId, lastMessageId);
            }

            set(state => {
              const fetchedHistories = [...state.fetchedHistories];
              const fetchedHistoryItem = fetchedHistories.find(fh => fh.id === historyRes.character.id);

              // save new
              if (!fetchedHistoryItem) {
                fetchedHistories.push({
                  id: historyRes.character.id,
                });
                // clear previously possible errors
              } else if (fetchedHistoryItem.error) {
                fetchedHistoryItem.error = undefined;
              }

              return {
                chatHistory: {
                  ...state.chatHistory,
                  [characterId]: mergeHistories(historyRes, state.chatHistory[characterId]),
                },
                fetchedHistories,
              };
            });

            // Check if characterId is not in chatPreview
            if (!get().recentChats.some(chat => chat.character_id === characterId)) {
              // Call getUserRecentChats to update the recent chats
              get().getUserRecentChats();
          }
          }
        } catch (e) {
          const error = e as AxiosError;
          // @ts-ignore
          const errorMessage = error.response?.data?.detail || error.message;
          showToast(`Can\'t get chat details. ${errorMessage}`, ToastType.ERROR);

          set(state => {
            const fetchedHistories = [...state.fetchedHistories];
            const fetchedHistoryItem = fetchedHistories.find(fh => fh.id === characterId);

            if (!fetchedHistoryItem) {
              fetchedHistories.push({
                id: characterId,
                error: errorMessage,
              });
            } else {
              fetchedHistoryItem.error = errorMessage;
            }

            return { fetchedHistories };
          });
        } finally {
          set({ loading: false, loadingMore: false });
        }
      },
      pruneExpiredChatHistory: (characterId: number) => {
        set(state => {
          const chatHistory = state.chatHistory[characterId];
          if (!chatHistory) return state;

          const isMessageExpired = (msg: IChatMessage): boolean => {
            if (!msg.image_data) return false;
            try {
              const imageUrl = new URL(msg.image_data);
              const amzDate = imageUrl.searchParams.get('X-Amz-Date');
              const amzExpires = imageUrl.searchParams.get('X-Amz-Expires');
              if (!amzDate || !amzExpires) return false;

              const date = new Date(
                Date.UTC(
                  parseInt(amzDate.substring(0, 4)), // Year
                  parseInt(amzDate.substring(4, 6)) - 1, // Month (0-based)
                  parseInt(amzDate.substring(6, 8)), // Day
                  parseInt(amzDate.substring(9, 11)), // Hour
                  parseInt(amzDate.substring(11, 13)), // Minute
                  parseInt(amzDate.substring(13, 15)) // Second
                )
              );

              const expiresInSeconds = parseInt(amzExpires);
              const expireDate = new Date(date.getTime() + expiresInSeconds * 1000);
              return expireDate < new Date(Date.now() + 24 * 3600 * 1000);
            } catch (error) {
              console.error('Error parsing image_data URL:', error);
              return false;
            }
          };

          const latestExpiredIndex = chatHistory.history.findLastIndex(isMessageExpired);
          if (latestExpiredIndex === -1) return state;
          const updatedHistory = chatHistory.history.slice(latestExpiredIndex + 1);
          return { chatHistory: { ...state.chatHistory, [characterId]: { ...chatHistory, history: updatedHistory } } };
        });
      },
      getUserRecentChats: async () => {
        set({ recentChatsLoading: true });
        const res = await charactersApi.getUserRecentChats();

        if (res instanceof AxiosError) {
          const message = res.response?.data.detail;
          showToast(message, ToastType.ERROR);
          set({ recentChatsLoading: false });
          return;
        }

        const recentChats = res;
        const { chatHistory } = get();
        const historyCopy = { ...chatHistory };

        recentChats.forEach(ch => {
          if (!historyCopy[ch.character_id]) {
            historyCopy[ch.character_id] = {
              character: {
                id: ch.character_id,
                name: ch.character_name,
                description: '',
                avatar_url: ch.avatar_url || default_avatar,
                profile: '',
                subtitle: '',
              },
              history: ch.history || [],
            };
          }
        });

        // chatHistory prepopulates chats by last 10 messages from getUserRecentChats
        set({ recentChats, chatHistory: historyCopy });
        set({ recentChatsLoading: false });
      },
      sendMessage: async (characterId, text, imageRequest, onQuotaHit, onSuccess) => {
        function removeLastMessage() {
          set(state => {
            const chatDetails = state.chatHistory[characterId];
            const history = [...chatDetails.history];
            history.pop();

            return {
              chatHistory: {
                ...state.chatHistory,
                [characterId]: {
                  ...chatDetails,
                  history,
                },
              },
            };
          });
        }

        function addMessageToHistory(message: IChatMessage) {
          set(state => {
            const currentHistory = state.chatHistory[characterId];
            currentHistory?.history.push(message);

            return state;
          });
        }

        function getUpdatedRecentChatsByLastInteraction(message: IChatMessage): IRecentChat[] {
          return [...get().recentChats].map(item => {
            if (item.character_id === characterId) {
              item.last_interaction_time = new Date();
              item.msg_preview = message.text;
              item.history = [...item.history.slice(-9), message];
            }
            return item;
          });
        }

        try {
          addMessageToHistory({
            id: new Date().getTime(),
            time_sent: new Date().getTime() / 1000,
            sender: 'user',
            text,
            image_request: imageRequest,
          });
          set({ sendingMessage: true });
          const res = imageRequest ? await charactersApi.requestImage(characterId, text) : await charactersApi.sendMessageToChar(characterId, text);
          window.gtag && window.gtag('event', 'send_message_attempt', {'character_id': characterId, 'image_request': imageRequest});
          posthog.capture('send_message_attempt', {'character_id': characterId, 'image_request': imageRequest});

          // Handle errors
          if (res instanceof AxiosError) {
            removeLastMessage();
            const message = res.response?.data.detail;
            if (message) {
              showToast(message, ToastType.ERROR);
            }
            window.gtag && window.gtag('event', 'send_message_failed', {'character_id': characterId, 'reason': message, 'image_request': imageRequest});
            posthog.capture('send_message_failed', {'character_id': characterId, 'reason': message, 'image_request': imageRequest});
            return;
          }

          window.gtag && window.gtag('event', 'send_message_success', {'character_id': characterId, 'image_request': imageRequest});
          posthog.capture('send_message_success', {'character_id': characterId, 'image_request': imageRequest});
          const answer = res[0];

          if (answer) {
            // Handle qouta hit
            if ('message_locked' in answer) {
              onQuotaHit({ ...answer, originalText: text, imageRequest: imageRequest });
              removeLastMessage();
            } else {
              // Handle success case
              addMessageToHistory({
                sender: 'character',
                ...answer,
              });
              const current = get().recentChats.find(ch => ch.character_id === characterId);
              if (!current) {
                get().getUserRecentChats();
              } else {
                const newMessage: IChatMessage = {
                  sender: 'character',
                  ...answer,
                };
                set({ recentChats: getUpdatedRecentChatsByLastInteraction(newMessage) });
              }

              setTimeout(onSuccess, 500);
            }
          }
        } catch (error) {
          set({ error });
        } finally {
          set({ sendingMessage: false });
        }
      },
      unlockMessages: async (quotaResponse: IChatMessageQuotaResponse, characterId: number) => {
        const res = await charactersApi.unlockMessages(characterId);
        await get().updateGemBalance(res);
        const isError = res instanceof AxiosError;
        if (isError) {
          showToast(res.response?.data.detail || 'Unable to unlock', ToastType.ERROR);
          return false;
        }
        else {
          const success = !isError && res.unlock_successful;    
          if (!success) {
            showToast('Unable to unlock', ToastType.ERROR);
          }
          return success;
        }
      },
      regenerateLastMessage: async id => {
        let initialMessage: IChatMessage;

        set(state => {
          const { chatHistory } = state;
          const { history } = chatHistory[id];
          const lastMessage = history.findLast(m => m.sender === 'character');

          // clear last char message data
          if (lastMessage) {
            // save copy of the message we override in case of errors
            initialMessage = { ...lastMessage };
            lastMessage.image_data = undefined;
            lastMessage.text = '';
          }

          return { sendingMessage: true, chatHistory };
        });

        // clear last char message data
        const res = await charactersApi.regenerateLastMessage(id);

        if (res instanceof AxiosError) {
          if (res.response?.data.detail) {
            showToast(res.response.data.detail, ToastType.ERROR);
          }

          // set original message data back
          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[id];
            const lastMessage = history.findLast(m => m.sender === 'character');

            if (lastMessage) {
              Object.assign(lastMessage, initialMessage);
            }

            return { sendingMessage: false, chatHistory };
          });
        } else {
          set(state => {
            const { chatHistory } = state;
            const { history } = chatHistory[id];
            const updatedMessage = history.find(m => m.id === initialMessage.id);

            if (updatedMessage) {
              Object.assign(updatedMessage, { ...res, text: res.text });
            }

            return { sendingMessage: false, chatHistory };
          });
        }
      },
      getById: id => get().characters.find(c => c.id === id),
      resetChat: async (id, onQuotaHit, onSuccess) => {
        try {
          set({ resetingChat: true });
          const res = await charactersApi.resetChat(id);

          if (!(res instanceof AxiosError)) {
            const answer = res[0];
            if (answer && 'message_locked' in answer) {
              // Handle qouta hit
              const text = answer.text || '';
              onQuotaHit({ ...answer, originalText: text, imageRequest: false });
            } 
            else {
              set(state => {
                const targetHistory = state.chatHistory[id];
                // clear existing history, keep only welcome message
                targetHistory.history = [];
                if (res && res[0] && 'id' in res[0]) {
                  const resetMessage : IChatMessage = {
                    ...res[0],
                    sender: 'character',
                  };
                  targetHistory.history.push(resetMessage);
                }
                return state;
              });
              get().getUserRecentChats();
              onSuccess();
            }
          }
        } catch (e) {
          const error = e as AxiosError;
          // @ts-ignore
          const errorMessage = error.response?.data?.detail || error.message;
          showToast(`Can\'t reset chat. ${errorMessage}`, ToastType.ERROR);
        } finally {
          set({ resetingChat: false });
        }
      },
      deleteChat: async (id, onSuccess) => {
        try {
          set({ deletingChat: true });
          const res = await charactersApi.deleteChat(id);

          if (!(res instanceof AxiosError)) {
            set(state => ({ recentChats: state.recentChats.filter(chat => chat.character_id !== id) }));

            if (onSuccess) {
              onSuccess();
            }
          }
        } catch (e) {
          const error = e as AxiosError;
          // @ts-ignore
          const errorMessage = error.response?.data?.detail || error.message;
          showToast(`Can\'t delete chat. ${errorMessage}`, ToastType.ERROR);
        } finally {
          set({ deletingChat: false });
        }
      },
      updateMessage: async (messageId, characterId, fields) => {
        set(state => {
          const { chatHistory } = state;
          const chatDetails = chatHistory[characterId];
          const messageIndex = chatDetails.history.findIndex(m => m.id === messageId);
          const message = chatDetails.history[messageIndex];
          const historyCopy = [...chatDetails.history];

          if (message && fields) {
            const updatedMessage: IChatMessage = {
              ...message,
              ...fields,
            };

            historyCopy.splice(messageIndex, 1, updatedMessage);
          }

          return {
            chatHistory: {
              ...chatHistory,
              [characterId]: {
                ...chatDetails,
                history: historyCopy,
              },
            },
          };
        });
      },
      unlockImage: async (message, characterId, onPaywallHit: (config: IUnlockImageResponse) => void) => {
        const { updateMessage } = get();

        updateMessage(message.id, characterId, { unlockPending: true });

        if (!message.image_id) {
          updateMessage(message.id, characterId, { unlockPending: false });
          return false;
        }

        const res = await charactersApi.unlockImage(characterId, message.image_id);
        await get().updateGemBalance(res);
        if (!(res instanceof AxiosError)) {
          if ('unlock_successful' in res && !res.unlock_successful) {
            if (res.subscription_required) {
              onPaywallHit(res as unknown as IUnlockImageResponse);
            }
            else {
              onNotEnoughBalance();
            }
            updateMessage(message.id, characterId, {
              unlockPending: false,
              image_locked: true,
            });
            return false;
          }

          updateMessage(message.id, characterId, {
            unlockPending: false,
            image_locked: !res.unlock_successful,
            image_data: res.image_data,
          });
        } else {
          updateMessage(message.id, characterId, { unlockPending: false });

          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }

        return true;
      },
      reset: async () => set({ chatHistory: {} }),
      getCharacterAlbums: async (id: number) => {
        const res = await charactersApi.getAlbums(id);
        if (res instanceof AxiosError) {
          showToast(res.message, ToastType.ERROR);
        } else {
          const prevPendingImages = get().getTotalPendingImages(id);
          set({ characterAlbums: { ...get().characterAlbums, [id]: res } });
          const newPendingImages = get().getTotalPendingImages(id);
          const difference = Math.max(prevPendingImages - newPendingImages, 0);
          set(state => ({
            characterNewImages: {
              ...state.characterNewImages,
              [id]: (state.characterNewImages[id] || 0) + difference
            }
          }));
        }
      },
      updateAlbumImage: async (characterId: number, updatedImageData: IImageDataInAlbum) => {
        set(state => {
          const allCharacterAlbums = state.characterAlbums[characterId] || [];

          if (!Array.isArray(allCharacterAlbums)) {
            return state; // Return the current state without changes
          }

          const updatedAlbums = allCharacterAlbums.map(album => {
            const updatedImages = album.image_data?.map(imageData => {
              if (imageData.image_id === updatedImageData.image_id) {
                return { ...imageData, ...updatedImageData };
              }
              return imageData;
            });
            return { ...album, image_data: updatedImages };
          });

          return {
            characterAlbums: {
              ...state.characterAlbums,
              [characterId]: updatedAlbums,
            },
          };
        });
      },
      unlockAlbumImage: async (imageData: IImageDataInAlbum, characterId: number, onPaywallHit: (config: IUnlockImageResponse) => void) => {
        const { updateAlbumImage } = get();
        updateAlbumImage(characterId, { ...imageData, unlockPending: true } );

        const res = await charactersApi.unlockImage(characterId, imageData.image_id);
        await get().updateGemBalance(res);

        if (!(res instanceof AxiosError)) {
          if (!res.unlock_successful) {
            if (res.subscription_required) {
              onPaywallHit(res as unknown as IUnlockImageResponse);
            }
            else {
              onNotEnoughBalance();
            }
            updateAlbumImage(characterId, { ...imageData, unlockPending: false } );
            return false;
          }

          updateAlbumImage(characterId, {
             ...imageData, 
             unlockPending: false,
             image_locked: !res.unlock_successful,
             image_url: res.image_data ? res.image_data : imageData.image_url,
           });
        } else {
          updateAlbumImage(characterId, { ...imageData, unlockPending: false });

          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }

        return true;
      },
      generateAudio: async (messageId: number, characterId: number, messageText: string, isAuthenticated: boolean, onPaywallHit: (config: IAudioMessageQuotaResponse) => void): Promise<AudioGenerationResult> => {
        try {
          const response = await charactersApi.generateAudio(messageId, characterId, messageText);
          
          // Currently it use stream API, so gem_balance can't be returned as part of API response, 
          // instead of calling, 
          //    await get().updateGemBalance(response);
          // it needs to make an additional call to update gem balance
          await useUserStore.getState().getUserStats();

          if (response) {
            if ('audio_locked' in response) {
              if (response.subscription_required) {
                onPaywallHit(response as unknown as IAudioMessageQuotaResponse);
              }
              else {
                onNotEnoughBalance();
              }
              return { success: false, reason: 'audio_locked' };
            }
            // Check if the response is audio/mpeg or JSON
            if (response instanceof ArrayBuffer) {
              // It's an audio file
              const audioBlob = new Blob([response], { type: 'audio/mpeg' });
              return { success: true, audioBlob };
            } else if (typeof response === 'object') {
              // It's likely JSON
              if ('audio_locked' in response) {       
                onPaywallHit(response as unknown as IAudioMessageQuotaResponse);
                return { success: false, reason: 'audio_locked' };
              } else {
                console.error('Unexpected JSON response:', response);
                return { success: false, reason: 'other_error' };
              }
            } else {
              console.error('Unexpected response type:', typeof response);
              return { success: false, reason: 'other_error' };
            }
          } else {
            console.error('Response is undefined');
            return { success: false, reason: 'other_error' };
          }
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 402) {
            return { success: false, reason: 'insufficient_gems' };
          }
          console.error('Error generating audio:', error);
          return { success: false, reason: 'other_error' };
        }
      },
      unlockAudioMessages: async (quotaResponse: IAudioMessageQuotaResponse) => {
        if (!get().checkBalance(quotaResponse.gem_cost)) {
          return false;
        }
        const res = await charactersApi.unlockAudioMessages();
        await get().updateGemBalance(res);

        const isError = res instanceof AxiosError;
        if (isError) {
          showToast(res.response?.data.detail || 'Unable to unlock', ToastType.ERROR);
          return false;
        }
        else {
          const success = !isError && res.unlock_successful;    
          if (!success) {
            showToast('Unable to unlock', ToastType.ERROR);
          }
          return success;
        }
      },
      createCharacter: async (choices: Record<string, any>, onPaywallHit: (config: ICharacterQuotaResponse) => void, variant?: string | null) => {
        const res = await charactersApi.createCharacter(choices, variant);
        await get().updateGemBalance(res);
        
        if (res instanceof AxiosError) {
          // Handle API error
          const errorMessage = res.response?.data?.detail || res.message;
          showToast(`Character creation failed. ${errorMessage}`, ToastType.ERROR);
          return null;
        }

        if (res && 'character_locked' in res) {
          if (res.subscription_required) {
            onPaywallHit(res);
          }
          else {
            onNotEnoughBalance();
          }
          return null;
        }
        
        if (res && 'character' in res) {
          const returnedCharacter = res.character;
          // add character to the list, update existing if already exists
          set(state => ({
            characters: state.characters.some(char => char.id === returnedCharacter.id)
              ? state.characters.map(char => char.id === returnedCharacter.id ? returnedCharacter : char)
              : [...state.characters, returnedCharacter]
          }));
          return res;
        }

        showToast('Unexpected error occurred', ToastType.ERROR);
        return null;
      },
      renameCharacter: async (characterId: number, name: string): Promise<ICharacter | null> => {
        const res = await charactersApi.renameCharacter(characterId, name);
        if (res instanceof AxiosError) {
          showToast(res.response?.data.detail || 'Unable to rename', ToastType.ERROR);
          return null;
        }
        if (res && 'character' in res) {
          const returnedCharacter : ICharacter = res.character as ICharacter;
          // add character to the list, update existing if already exists
          set(state => ({
            characters: state.characters.some(char => char.id === returnedCharacter.id)
              ? state.characters.map(char => char.id === returnedCharacter.id ? returnedCharacter : char)
              : [...state.characters, returnedCharacter]
          }));
          return returnedCharacter;
        }
        showToast('Unexpected error occurred', ToastType.ERROR);
        return null;
      },
      unlockCharacters: async (quotaResponse: ICharacterQuotaResponse) => {
        const res = await charactersApi.unlockCharacters();
        await get().updateGemBalance(res);
        if (res instanceof AxiosError) {
          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }
        
        if (!res.unlock_successful) {
          onNotEnoughBalance();
          return false;
        }
        else {
          return true;
        }
      },
      unlockImageGen: async (quotaResponse: IImageGenQuotaResponse) => {
        if (!get().checkBalance(quotaResponse.gem_cost)) {
          return false;
        }
        const res = await charactersApi.unlockImageGen();
        await get().updateGemBalance(res);
        if (res instanceof AxiosError) {
          const error = res.response?.data.detail;
          if (error) {
            showToast(error, ToastType.ERROR);
          }
          return false;
        }
        
        if (!res.unlock_successful) {
          onNotEnoughBalance();
          return false;
        }
        else {
          return true;
        }
      },
      updateGemBalance: async(res: any) => {
        if ('gem_balance' in res && res.gem_balance && typeof res.gem_balance === 'number') {
          await useUserStore.getState().setUserStats({ gem_balance: res.gem_balance });
        }
      },
      getTotalPendingImages: (characterId: number) => {
        let totalPending = 0;
        if (get().characterAlbums[characterId]) {
          totalPending = get().characterAlbums[characterId].reduce((total, album) => {
            return total + (album.images_pending ?? 0);
          }, 0);
        }
        return totalPending;
      },
      getCharacterNewImages(characterId: number) {
        return get().characterNewImages[characterId];
      },
      clearCharacterNewImages(characterId: number) {
        set(state => ({
          ...state,
          characterNewImages: {
            ...state.characterNewImages,
            [characterId]: 0
          }
        }));
      },
    }),
    {
      name: charactersStorePersistKey,
      partialize: state => ({
        characters: state.characters,
        recentChats: state.recentChats,
        chatHistory: state.chatHistory,
        characterAlbums: state.characterAlbums,
      }),
      onRehydrateStorage: () => (state) => {
        if (state) {
          // Prune expired messages for all characters in the chat history
          Object.keys(state.chatHistory).forEach(characterId => {
            state.pruneExpiredChatHistory(Number(characterId));
          });
        }
      },
    },
  ),
);
