import { ToastMessage, ToastType } from '@/types/toast';
import { ConfirmationMessage, ConfirmationType } from '@/types/confirmation';
import { create } from 'zustand';
import { useUserStore } from './user';
import { useCharactersStore } from './characters';
import { useProductsStore } from './products';
import { SignOutParams, signOut } from 'next-auth/react';
import { clearPersist, clearWelcomMessagesMap } from '@/helpers/localStorage';
import { Banner } from '@/models/banner';
import bannersApi from '@/api/banners';
import { AxiosError } from 'axios';
import { getDeviceTags, getUTMTags } from '@/lib/utils';
import { ICharacter } from '@/models/characters';
import { Session } from 'next-auth';
import posthog from 'posthog-js';

export interface AppState {
  session: Session | null;
  setSession: (session: Session | null) => void;
  banners: Banner[];
  bannersLoading: boolean;
  isSignInModalVisible: boolean;
  isSidebarOpen: boolean;
  preSignInCharacter: ICharacter | null;
  preSignInCharacters: ICharacter[] | null;
  setPreSignInCharacter: (character: ICharacter | null) => void;
  setPreSignInCharacters: (characters: ICharacter[] | null) => void;
  setIsSignInModalVisible: (isVisible: boolean) => void;
  signInRedirectUrl: string | null;
  setSignInRedirectUrl: (redirectUrl: string | null) => void;
  signInUiType: 'signinUI' | 'signupUI';
  setSignInUiType: (uiType: 'signinUI' | 'signupUI') => void;
  toggleSidebar: () => void;
  hideSidebar: () => void;
  getBanners: () => void;
  toast: ToastMessage | null;
  showToast: (toast: ToastMessage, hideDelay?: number) => void;
  confirmation: ConfirmationMessage | null;
  showConfirmation: (confirmation: ConfirmationMessage | null) => void;
  logOut: (options?: SignOutParams) => void;
}

export const useAppStore = create<AppState>()(set => ({
  session: null,
  setSession: (session) => set(state => {
    if (state.session === null && session !== null) {
      window.gtag && window.gtag('event', 'logged_in');
      posthog.capture('logged_in');
    }
    return { session };
  }),  
  toast: null,
  confirmation: null,
  isSidebarOpen: false,
  isSignInModalVisible: false,
  bannersLoading: false,
  preSignInCharacter: null,
  preSignInCharacters: null,
  signInRedirectUrl: null,
  signInUiType: 'signinUI',
  banners: [],
  setPreSignInCharacter: (character: ICharacter | null) => set({ preSignInCharacter: character }),
  setPreSignInCharacters: (characters: ICharacter[] | null) => set({ preSignInCharacters: characters }),
  setSignInRedirectUrl: (redirectUrl: string | null) => set({ signInRedirectUrl: redirectUrl }),
  setSignInUiType: (uiType: 'signinUI' | 'signupUI') => set({ signInUiType: uiType }),
  setIsSignInModalVisible: (isVisible: boolean) => set({ isSignInModalVisible: isVisible }),
  toggleSidebar: () => set(state => ({ isSidebarOpen: !state.isSidebarOpen })),
  hideSidebar: () => set({ isSidebarOpen: false }),
  showToast: (toast, hideDelay) => {
    set({ toast });
    setTimeout(() => {
      set(() => ({
        toast: null,
      }));
    }, hideDelay || 4000);
  },
  showConfirmation: (confirmation) => {
    set({ confirmation });
  },
  getBanners: async () => {
    set({ bannersLoading: true });
    const deviceTags = getDeviceTags();
    const utmTags = getUTMTags();
    const tags = [...deviceTags, ...utmTags];
    const res = await bannersApi.getBanners(tags);
    if (res instanceof AxiosError) {
      showToast(`Can\'t get banners: ${res.response?.data.detail}`, ToastType.ERROR);
      set({ bannersLoading: false });
    } else {
      set({ banners: res });
      set({ bannersLoading: false });
    }
  },
  logOut: async options => {
    await signOut(options);
    useUserStore.getState().reset();
    useCharactersStore.getState().resetUserCharacters();
    useCharactersStore.getState().reset();
    useProductsStore.getState().reset();
    clearPersist();
    clearWelcomMessagesMap();
  },
}));

export function showToast(message: string, type: ToastType, delay?: number) {
  useAppStore.getState().showToast(
    {
      type,
      message,
    },
    delay,
  );
}
